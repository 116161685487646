.EditorStyle {
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.EditorStyle:focus-within {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}