.email {
    cursor: pointer;
}

.editor {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 16px;
    border-radius: 2px;
    margin-bottom: 2em;
    box-shadow: inset 0px 1px 8px -3px #ABABAB;
    background: #fefefe;
}

.editor :global(.public-DraftEditor-content) {
    min-height: 140px;
}